<template>
  <div class="container">
    <div class="image-container">
      <img :src="require('@/assets/image/invite.png')" alt="" class="invite-image" />
      <div class="invite-info">
        <van-row>
          <van-col class="description" span="24">
            {{ $t("CUSDT next-gen mobile node mining") }}
          </van-col>
        </van-row>
        <van-row gutter="20">
          <van-col span="24" class="qr-code-container">
            <img :src="qrUrl" alt="" class="qr-code" />
          </van-col>
        </van-row>
        <div class="invite-goto-detail">  
          <van-button round plain size="small" class="invite-goto-button" @click="$util.router.goto('/agent/index')">
            <van-icon name="chart-trending-o" /> {{ $t("View Invitation Details") }}
          </van-button>
        </div>
      </div>
    </div>
  </div>

  <div class="rewards-container">
    <van-row @click="copyLink">
      <van-col span="24" class="centered">
        <img :src="require('@/assets/image/copy.png')" alt="" class="copy-icon" />
      </van-col>
      <van-col span="24" class="centered">
        {{ $t("Copy Link") }}
      </van-col>
    </van-row>

    <van-row class="rewards-details hide">
      <van-col span="24" class="rewards-title">
        {{ $t("Invitation Rewards") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 1...") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 2...") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 3...") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Example") }}：
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("Invitation Rewards 4...") }}
      </van-col>
    </van-row>
    <van-row class="rewards-details">
      <van-col span="24" class="rewards-title">
        {{ $t("Invitation Rewards") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("After successfully redeeming, users will receive invitation privileges.") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("When invited subordinates successfully redeem, they are considered valid invitations.") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("If a team member invited by the inviter successfully redeems 5 USDT, the inviter will receive a portion of the invited user's rewards.") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("For example:") }}：
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("User A invites User B. After B successfully mines, A receives 30% of B’s rewards.") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("User B invites User C. After C successfully mines, B receives 30% of C’s rewards.") }}
      </van-col>
      <van-col span="24" class="rewards-description">
        {{ $t("In this case, User A also receives 10% of C’s rewards.") }}
      </van-col>
    </van-row>
  </div>

  <div class="spacer"></div>

  <input v-model="code" ref="input" />
</template>

<script>
const Qrcode = require("qrcode");

const protocol = window.location.protocol; // 获取协议（http: 或 https:）
const currentUrl = `${protocol}//${window.location.host}${process.env.VUE_APP_PUBLIC_PATH}`;

export default {
  name: "invite",
  data() {
    return {
      baseURL: currentUrl,
      qrUrl: "",
      text: "",
      invite_code: currentUrl,
      tronweb: "",
      code: "",
      dataid: "",
    };
  },
  methods: {
    copyLink() {
      const textarea = document.createElement('textarea');
      textarea.value = this.invite_code;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy');
        this.$toast(this.$t("Successful"));
      } catch (err) {
        console.error('Copy failed', err);
        this.$toast(this.$t("Unsuccessful"));
      } finally {
        document.body.removeChild(textarea);
      }
    }
  },
  mounted() {
    this.$api.users.info().then(res=>{
      if (res.data.code == 1) {
          const userInfo = res.data.data;

          this.dataid = userInfo.id;
          this.code = this.baseURL + `#/?invite_code=${userInfo.id}`;
          this.invite_code = this.code;
          
          Qrcode.toDataURL(this.code, (err, url) => {
            console.log(this.code)
            console.log("Qrcode.toDataURL", url)
            this.qrUrl = url;
          });
      } else {
        Qrcode.toDataURL(this.baseURL, (err, url) => {
          this.qrUrl = url;
        });
      }
    }).catch(err=>{
        Qrcode.toDataURL(this.baseURL, (err, url) => {
          this.qrUrl = url;
        });
    })
  },
};
</script>

<style scoped>
.container {
  padding: 30px;
  box-sizing: border-box;
}

.image-container {
  width: 100%;
}

.invite-image {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.invite-info {
  width: 100%;
  background-color: rgb(33, 34, 68);
  padding: 10px 20px;
  box-sizing: border-box;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.invite-code {
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  word-wrap: break-word;  
  overflow-wrap: break-word;
  white-space: normal; 
}

.description {
  /* margin-top: 20px; */
  margin-bottom: 10px;
  color: #7080b3;
  text-align: center;
}

.qr-code-container {
  text-align: center;
}

.qr-code {
  width: 50vw;
  height: 50vw;
  border-radius: 10px;
}

.rewards-container {
  background-color: rgb(33, 34, 68);
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
}

.centered {
  text-align: center;
}

.copy-icon {
  width: 50px;
  height: 50px;
}

.rewards-details {
  margin-top: 30px;
}

.rewards-title {
  font-size: 1.125rem;
}

.rewards-description {
  margin-top: 15px;
  color: #7080b3;
}

.spacer {
  height: 90px;
}
.invite-goto-detail {
  display: flex; 
  justify-content: center; 
  margin-top: 5px;
  color: #f90;
}

.invite-goto-button {
  color: #a96ff7;
  padding: 15px 15px 15px 30px;
  /* background: hsla(266, 89%, 70%, 0); */
  background: rgba(0, 0, 0, 0);
  border: 0 !important;
}
</style>
