<template>
  <div class="container">
    <van-row class="container-top">
      <van-col span="24">
        <van-row gutter="" justify="space-around" class="bottom-line">
          <van-col span="12" class="content-box-1">
            <div class="color-blue">
              <div>{{ $t("Total Invites") }}</div>
              <div style="font-size: 8px; line-height: 8px;">({{ $t("Primary") }} / {{ $t("Secondary") }})</div>
            </div>
            <div class="content-box-1-number">
              <df-count-up class="count-up" :duration="300" :endVal="summary.total_invites_first" />
              <span style="padding: 0 5px;"> / </span>
              <df-count-up class="count-up" :duration="300" :endVal="summary.total_invites_second" />
            </div>
          </van-col>
          <van-col span="12" class="content-box-1">
            <div class="color-blue">
              <div>{{ $t("Today's Invites") }}</div>
              <div style="font-size: 8px; line-height: 8px;">({{ $t("Primary") }} / {{ $t("Secondary") }})</div>
            </div>
            <div class="content-box-1-number">
              <df-count-up class="count-up" :duration="300" :endVal="summary.today_invites_first" />
              <span style="padding: 0 5px;"> / </span>
              <df-count-up class="count-up" :duration="300" :endVal="summary.today_invites_second" />
            </div>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24">
        <van-row gutter="" justify="space-around" class="">
          <van-col span="12" class="content-box-2">
            <div class="color-blue">{{ $t("Total Rebates") }}</div>
            <df-count-up class="count-up-2"  :endVal="summary.total_rabates" :decimals="4"/>
          </van-col>
          <van-col span="12" class="content-box-2">
            <div class="color-blue">{{ $t("Today's Rebates") }}</div>
            <df-count-up class="count-up-2" :endVal="summary.today_rabates" :decimals="4"/>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
  <van-list :loading="isLoading" :finished="isFinished" :offset="0"
    loadingText="Loading..." finished-text="Nomore" @load="loadList">
    <van-row class="bottom-line" style="margin-top: 20px;"></van-row>
    <van-row gutter="" v-for="(item, index) in list" :key="index">
      <van-col span="24" class="invite-list-item bottom-line">
        <div>
          <div class="__address">
            <span>{{ item.user_address }}</span>
            <van-tag v-if="item._pos==1" round type="success">{{ $t("Primary") }}</van-tag>
            <van-tag v-else round type="warning">{{ $t("Secondary") }}</van-tag>
          </div>
          <div class="__time">{{ item.create_time }}</div>
        </div>
        <div class="__tags color-blue">
          {{ $t("Successful") }}
        </div>
      </van-col>
    </van-row>
  </van-list>
  <div style="height: 120px"></div>
</template>

<script>

export default {
  name: "agentIndex",
  data() {
    return {
      summary: {
        total_invites_first: 0,
        total_invites_second: 0,
        today_invites_first: 0,
        today_invites_second: 0,
        total_rabates: 0,
        today_rabates: 0,
      },
      list: [],
      isLoading: false,
      isFinished: false,
      page: 1
    };
  },
  methods: {
    init: function() {
      this.$api.users.invitation().then(res => {
        if (res.data.code == 1) {
          let result = res.data.data;
          this.summary = result.summary;
        }
      }).finally(()=>{
        this.loadList();
      });
    },
    loadList: function() {
      console.log('loadList', this.isLoading, this.page)
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      this.$api.users.invitation_list({page: this.page}).then(res => {
        console.log('invitation_list', res)
        if (res.data.code == 1) {
          let result = res.data.data;
          // 将新数据追加到现有的 list 中
          this.list = this.list.concat(result.list);
          this.isFinished = result.list.length === 0
        }
        this.page = this.page + 1;
      }).finally(()=>{
        this.isLoading = false
      });
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  /* 30px */
  overflow: auto;
}

.container-top{
  background-color: rgba(33, 34, 68, 1);
  border-radius: 1.5rem;
}

.color-blue{
  text-align: center;
  line-height: 20px;
  font-weight: 550;
}

.content-box-1, .content-box-2 {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  line-height: 38px;
}

.content-box-1-number{
  display: flex;
}

.count-up, .count-up-2{
  font-size: 1.8rem;
  font-weight: 550;
}
.count-up-2{
  font-size: 1.5rem;
}

.invite-list-item{
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invite-list-item .__address {
  font-size: 18px;
  line-height: 36px;
  >span {
    margin-right: 5px;
  }
}
.invite-list-item .__time {
  opacity: .5;
}
.invite-list-item .__tags {
  font-weight: 550;
  font-size: 14px;
}
</style>